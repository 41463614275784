import { atom } from "recoil";

export const mutedState = atom({
  key: "muted",
  default: true,
});

export const pauseState = atom({
  key: "pause",
  default: false,
});

export const forcePauseState = atom({
  key: "forcePause",
  default: false,
});

export const pauseBackgroundState = atom({
  key: "pauseBackground",
  default: false,
});

export const currentTrackIndexState = atom({
  key: "currentTrackIndex",
  default: 0,
});

export const openSidemenuState = atom({
  key: "openSidemenu",
  default: false,
});
