import AUDIO_DOLCEOLA from "./audio/dolceola.mp3";
import AUDIO_HORROR from "./audio/horror.mp3";
import AUDIO_JAZZGUITAR from "./audio/jazzguitar.mp3";
import AUDIO_MUSICBOX from "./audio/musicbox.mp3";
import AUDIO_NEOSOUL from "./audio/neosoul.mp3";
import AUDIO_SLOWPIANO from "./audio/slowpiano.mp3";
import AUDIO_TOYBOX from "./audio/toybox.mp3";
import AUDIO_WAFU from "./audio/wa-fu.mp3";

import BLESS from "./images/01.jpg";
import DAYDREAM from "./images/02.jpg";
import LIGHT from "./images/03.jpg";
import MEMORY from "./images/04.jpg";
import VANISH from "./images/05.jpg";
import NEON from "./images/06.jpg";
import TOYBOX from "./images/07.jpg";
import ETERNAL from "./images/08.jpg";

import INK_34 from "./images/ink/3_4.png";
import INK_HORROR from "./images/ink/horror.png";
import INK_JAPANEASE from "./images/ink/japanease.png";
import INK_JAZZ from "./images/ink/jazz-guitar.png";
import INK_MUSICBOX from "./images/ink/music-box.png";
import INK_NEOSOUL from "./images/ink/neo-soul.png";
import INK_PIANO from "./images/ink/slow-piano.png";
import INK_TOYBOX from "./images/ink/toy-box.png";

import INK_LAST_FRAME_34 from "./images/inkLastFrame/3_4.jpg";
import INK_LAST_FRAME_HORROR from "./images/inkLastFrame/horror.jpg";
import INK_LAST_FRAME_JAPANEASE from "./images/inkLastFrame/japanease.jpg";
import INK_LAST_FRAME_JAZZ from "./images/inkLastFrame/jazz-guitar.jpg";
import INK_LAST_FRAME_MUSICBOX from "./images/inkLastFrame/music-box.jpg";
import INK_LAST_FRAME_NEOSOUL from "./images/inkLastFrame/neo-soul.jpg";
import INK_LAST_FRAME_PIANO from "./images/inkLastFrame/slow-piano.jpg";
import INK_LAST_FRAME_TOYBOX from "./images/inkLastFrame/toy-box.jpg";

export type Track = {
  id: string;
  name: string;
  lead: string;
  jacket: string;
  audio: string;
  ink: string;
  inkLastFrame: string;
  text: string;
  flavorTextColor: "black" | "white";
};

const TRACKS: Track[] = [
  {
    id: "bless",
    name: "BLESS",
    lead: "君のそばにいるから",
    jacket: BLESS,
    audio: AUDIO_SLOWPIANO,
    ink: INK_PIANO,
    inkLastFrame: INK_LAST_FRAME_PIANO,
    text: "境界線の向こう側の君へ。\nどうかこっちを振り返らずに\nまっすぐに前へと進んで欲しい。\nこれからは離れ離れになるけれど\n君の中で僕は生き続けている。\n大丈夫、決してひとりじゃないから。",
    flavorTextColor: "black",
  },
  {
    id: "memory",
    name: "MEMORY",
    lead: "もういない君へ",
    jacket: MEMORY,
    audio: AUDIO_MUSICBOX,
    ink: INK_MUSICBOX,
    inkLastFrame: INK_LAST_FRAME_MUSICBOX,
    text: "色褪せてゆく君との記憶。\nあんなことがなければよかったと\n何度もあの日を悔やんだ。\nもう戻らない日々。\nせめて、思い出の中の君だけは\n安らかであるようにと祈った。",
    flavorTextColor: "black",
  },
  {
    id: "light",
    name: "LIGHT",
    lead: "明日へ優しく導いて",
    jacket: LIGHT,
    audio: AUDIO_JAZZGUITAR,
    ink: INK_JAZZ,
    inkLastFrame: INK_LAST_FRAME_JAZZ,
    text: "これから先\n何度もあの日のことを思い出すだろう。\nその時は、心に灯った小さなあかりが\nきっとふたりを照らしてくれる。\n一緒なら、どんな困難も\n乗り越えられるはずだから。",
    flavorTextColor: "black",
  },
  {
    id: "melt",
    name: "MELT",
    lead: "きみとぼくの輪郭さえも",
    jacket: DAYDREAM,
    audio: AUDIO_DOLCEOLA,
    ink: INK_34,
    inkLastFrame: INK_LAST_FRAME_34,
    text: "世界がゆっくりと流れていく。\n不思議と怖さも痛みも感じない。\nこんな時でも思い出すのは\n君との何気ない日々ばかり。\nふたりの存在がなくなるまで\nどうか一緒にいてほしい。",
    flavorTextColor: "black",
  },
  {
    id: "ending",
    name: "ENDING",
    lead: "向かう先はひとつだけ",
    jacket: VANISH,
    audio: AUDIO_HORROR,
    ink: INK_HORROR,
    inkLastFrame: INK_LAST_FRAME_HORROR,
    text: "足元から全てが崩れ去った。\n離れていく君の手も掴めず\n何もかもがどこかへ消えていく。\nどんなに願ってあがいても\n誰も助けに来てくれない。\n奈落は、もうすぐそこ。",
    flavorTextColor: "white",
  },
  {
    id: "neon",
    name: "NEON",
    lead: "きらきら煌めいて",
    jacket: NEON,
    audio: AUDIO_NEOSOUL,
    ink: INK_NEOSOUL,
    inkLastFrame: INK_LAST_FRAME_NEOSOUL,
    text: "彷徨い続けたこの道は\n決して平坦な道ではなかった。\nだけど挫けそうになったあの瞬間\n君が前を向くことを教えてくれた。\nこれからはもう迷わない。\n二人手を取り合って歩いていこう。",
    flavorTextColor: "black",
  },
  {
    id: "eternal",
    name: "ETERNAL",
    lead: "ふたりずっと永遠に",
    jacket: ETERNAL,
    audio: AUDIO_WAFU,
    ink: INK_JAPANEASE,
    inkLastFrame: INK_LAST_FRAME_JAPANEASE,
    text: "脳裏を駆け巡る鮮烈な記憶。\n確かに胸を刺す後悔も\nきっと悠久の時が癒してくれる。\nこれからは離れ離れにならないように\nしっかりと手を握っていよう。\n僕らを縛るものはもう何もないのだから。",
    flavorTextColor: "black",
  },
  {
    id: "crazy",
    name: "CRAZY",
    lead: "パーティしようぜ！",
    jacket: TOYBOX,
    audio: AUDIO_TOYBOX,
    ink: INK_TOYBOX,
    inkLastFrame: INK_LAST_FRAME_TOYBOX,
    text: "難しいことなんて考えない！\n行き先は風に任せちゃえば\nきっとどうにかなるもんさ。\n頭の中空っぽにして\n今を全力で楽しもう！\nさあ、準備はできた？",
    flavorTextColor: "black",
  },
];

export default TRACKS;
