import styles from "./Sidemenu.module.css";
import { ReactComponent as IconClose } from "./IconClose.svg";
import { ReactComponent as IconPlay } from "./IconPlay.svg";
import TRACKS from "../../tracks";
import { useRecoilValue } from "recoil";
import { currentTrackIndexState } from "../../recoil/atoms";

type SidemenuProps = {
  open: boolean;
  onClose: () => void;
};

const Sidemenu = ({ open, onClose }: SidemenuProps) => {
  const currentTrackIndex = useRecoilValue(currentTrackIndexState);

  return (
    <>
      <div className={styles.sidemenu} data-open={open}>
        <div className={styles.sidemenuInner}>
          <div className={styles.iconWrap}>
            <IconClose className={styles.iconClose} onClick={onClose} />
          </div>
          <div className={styles.sidemenuHeaderWrap}>
            <h2 className={styles.sidemenuHeader}>
              Which is your
              <br />
              “Rorschach syndrome”?
            </h2>
            <p className={styles.sidemenuSubheader}>
              あなたの
              <br />
              “ロールシャッハシンドローム”はどれ？
            </p>
          </div>
          <div>
            {TRACKS.map((track, index) => {
              return (
                <TrackItem
                  key={track.id}
                  id={track.id}
                  active={index === currentTrackIndex}
                  name={track.name}
                  closeSidemenu={onClose}
                />
              );
            })}
          </div>
          <div className={styles.toTop}>
            <a className={styles.toTopLink} href="/">
              ←TOP PAGE
            </a>
          </div>
          <div className={styles.copyright}>&copy; 2022 Outline Project</div>
        </div>
      </div>
      <div className={styles.overlay} onClick={onClose} data-open={open} />
    </>
  );
};

type TrackItemProps = {
  id: string;
  name: string;
  active?: boolean;
  closeSidemenu: () => void;
};

const TrackItem = ({ id, name, active, closeSidemenu }: TrackItemProps) => {
  const onClick = () => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });

      if (window.innerWidth <= 720) {
        closeSidemenu();
      }
    }
  };

  return (
    <div className={styles.trackItemRoot} onClick={onClick}>
      <div className={styles.trackItem}>
        <div className={styles.trackName}>{name}</div>
        <IconPlay className={styles.iconPlay} data-active={active} />
      </div>
    </div>
  );
};

export default Sidemenu;
