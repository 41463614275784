import React from "react";
import styles from "./AudioPlayer.module.css";
import { ReactComponent as IconForward } from "./IconForward.svg";
import { ReactComponent as IconPause } from "./IconPause.svg";
import { ReactComponent as IconPlay } from "./IconPlay.svg";
import { ReactComponent as IconRepeat } from "./IconRepeat.svg";
import { ReactComponent as IconRewind } from "./IconRewind.svg";
import { ReactComponent as IconMuted } from "./IconMuted.svg";
import TRACKS from "../../tracks";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentTrackIndexState,
  forcePauseState,
  mutedState,
  openSidemenuState,
  pauseState,
} from "../../recoil/atoms";
import ReactDOM from "react-dom";

const VOLUME = 0.75;

type AudioPlayerProps = {
  hidden: boolean;
};

const AudioPlayer = ({ hidden }: AudioPlayerProps) => {
  const openSidebar = useRecoilValue(openSidemenuState);

  const ref = React.useRef<HTMLAudioElement>(null);
  const [pause, setPause] = useRecoilState(pauseState);
  const [mute, setMute] = useRecoilState(mutedState);
  const [repeat, setRepeat] = React.useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useRecoilState(
    currentTrackIndexState
  );
  const refInitialized = React.useRef<boolean>(false);
  const source = TRACKS[currentTrackIndex];
  const image = source?.jacket;

  const forcePause = useRecoilValue(forcePauseState);

  const pause_ = pause || forcePause;

  React.useEffect(() => {
    if (pause_ || source == null || hidden) {
      return;
    }

    if (ref.current) {
      ref.current.pause();
    }

    const timer = setTimeout(() => {
      if (ref.current) {
        ref.current.volume = VOLUME;
        ref.current.play();
        refInitialized.current = true;
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [hidden, ref, source]);

  React.useEffect(() => {
    if (!refInitialized.current) {
      return;
    }

    if (ref.current == null) {
      return;
    }

    if (forcePause || pause) {
      ref.current.pause();
    } else {
      ref.current.volume = VOLUME;
      ref.current.play();
    }
  }, [pause, forcePause]);

  const onToggle = () => {
    if (ref.current == null) {
      return;
    }
    refInitialized.current = true;
    setPause(!pause);
  };

  const onToggleMute = () => {
    if (ref.current == null) {
      return;
    }

    ref.current.muted = !mute;
    setMute(!mute);
  };

  const onToggleRepeat = () => {
    if (ref.current == null) {
      return;
    }

    ref.current.loop = !repeat;
    setRepeat(!repeat);
  };

  const onChange = (v: number) => {
    const idx = v < 0 ? TRACKS.length - 1 : v % TRACKS.length;
    const track = TRACKS[idx];
    if (track == null) {
      return;
    }

    const element = document.getElementById(track.id);
    if (element == null) {
      return;
    }

    if (document.visibilityState === "visible") {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      setCurrentTrackIndex(idx);
    }
  };

  const onEnded = () => {
    onChange((currentTrackIndex + 1) % TRACKS.length);
  };

  const portal = document.getElementById("audio-root");
  if (portal == null) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={styles.audioPlayer}
      data-hidden={hidden}
      data-shift={openSidebar}
      // style={{ bottom: `${window.outerHeight - window.innerHeight}px` }}
    >
      <img
        key={image}
        className={styles.disk}
        src={image}
        data-playing={!pause}
        alt="disk"
      />
      <div className={styles.audioButtons}>
        <IconMuted
          className={styles.audioButton}
          role="button"
          onClick={onToggleMute}
          data-active={mute}
        />
        <IconRewind
          className={styles.audioButton}
          role="button"
          onClick={() => {
            onChange(currentTrackIndex - 1);
          }}
        />
        {pause ? (
          <IconPlay
            className={styles.audioButton}
            role="button"
            onClick={onToggle}
          />
        ) : (
          <IconPause
            className={styles.audioButton}
            role="button"
            onClick={onToggle}
          />
        )}
        <IconForward
          className={styles.audioButton}
          role="button"
          onClick={() => {
            onChange((currentTrackIndex + 1) % TRACKS.length);
          }}
        />
        <IconRepeat
          className={styles.audioButton}
          role="button"
          onClick={onToggleRepeat}
          data-active={repeat}
        />
      </div>
      <audio
        ref={ref}
        src={source?.audio || ""}
        onEnded={onEnded}
        muted={mute}
      />
    </div>,
    portal
  );
};

export default AudioPlayer;
