import styles from "./BackgroundVideo.module.css";
import MV from "../../movies/MV.mp4";
import { useRecoilValue } from "recoil";
import { pauseBackgroundState } from "../../recoil/atoms";
import { useEffect, useRef } from "react";

type BackgroundVideoProps = {
  blur: boolean;
};

const BackgroundVideo = ({ blur }: BackgroundVideoProps) => {
  const pause = useRecoilValue(pauseBackgroundState);
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (ref.current) {
      if (pause) {
        ref.current.pause();
      } else {
        ref.current.play();
      }
    }
  }, [pause, ref]);

  return (
    <div className={styles.backgroundVideo} data-blur={blur}>
      <video ref={ref} className={styles.video} muted autoPlay loop playsInline>
        <source src={MV} />
      </video>
    </div>
  );
};

export default BackgroundVideo;
