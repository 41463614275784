import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { mutedState, pauseState } from "./recoil/atoms";
import styles from "./SoundConfirm.module.css";
import TRACKS from "./tracks";

type SoundConfirmProps = {
  fadeout: boolean;
  onNext: () => void;
};

const preloadImage = (src: string) => {
  const img = new Image();
  img.src = src;
};

const preloadTrackImages = () => {
  for (let track of TRACKS) {
    preloadImage(track.jacket);
    preloadImage(track.ink);
  }
};

const SoundConfirm = ({ fadeout, onNext }: SoundConfirmProps) => {
  const setMuted = useSetRecoilState(mutedState);
  const setPause = useSetRecoilState(pauseState);
  const onOK = () => {
    setMuted(false);
    setPause(false);
    onNext();
  };

  const onNG = () => {
    setMuted(true);
    setPause(true);
    onNext();
  };

  useEffect(preloadTrackImages, []);

  return (
    <div className={styles.confirm} data-fadeout={fadeout}>
      <div className={styles.titleWrap}>
        <h2 className={styles.title}>Which is your “Rorschach syndrome”?</h2>
        <p className={styles.titleJa}>
          <span>あなたの</span>
          <span>“ロールシャッハ</span>
          <span>シンドローム”</span>
          <span>はどれ？</span>
        </p>
      </div>
      <div className={styles.bottomArea}>
        <div className={styles.box}>
          <button className={styles.no} onClick={onNG}>
            NG
          </button>
          <div className={styles.ask}>音楽が流れます</div>
          <button className={styles.ok} onClick={onOK}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default SoundConfirm;
