import styles from "./About.module.css";
import ImgSenario from "./senario.png";
import { ReactComponent as IconClose } from "./IconClose.svg";
import { ReactComponent as IconOpenInNew } from "./IconOpenInNew.svg";

type AboutProps = {
  open: boolean;
  onClose: () => void;
};

const About = ({ open, onClose }: AboutProps) => {
  return (
    <div className={styles.root} data-open={open}>
      <button className={styles.buttonClose} onClick={onClose}>
        <IconClose />
        close
      </button>
      <section className={styles.section}>
        <h2 className={styles.title}>What's this site?</h2>
        <div className={styles.titleJa}>このサイトについて</div>
        <p className={styles.text}>
          このサイトはディズム様（
          <a
            href="https://twitter.com/DizmKDC"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            @DizmKDC
          </a>
          ）作
          <br />
          エモクロアTRPGシナリオ
          <span className={styles.nowrap}>
            『ロールシャッハシンドローム』の
          </span>
          <br />
          非公式二次創作ファンサイトです。
        </p>
        <p className={styles.text}>
          本企画の内容には
          <br />
          重大なネタバレや独自の解釈・表現が多分に含まれています。
        </p>
      </section>
      <section className={styles.section}>
        <h2 className={styles.title}>
          Share your choice of “Rorschach Syndrome”.
        </h2>
        <div className={styles.titleJa}>
          あなたの“ロールシャッハシンドローム”を
          <span className={styles.nowrap}>シェアしよう</span>
        </div>
        <p className={styles.text}>
          DLが立てた卓の数だけ、
          <span className={styles.nowrap}>シナリオを経験したPLの数だけ、</span>
          <br />
          そして、参加したPCの
          <span className={styles.nowrap}>組み合わせの分だけ</span>
          <br />
          『ロールシャッハシンドローム』の
          <span className={styles.nowrap}>物語は存在します。</span>
        </p>
        <p className={styles.text}>
          自分（または共鳴者）
          <span className={styles.nowrap}>だけのかけがえのない物語。</span>
          <br />
          それを誰かに聞いてもらいたい。
          <br />
          だけど言葉だけでは
          <span className={styles.nowrap}>うまく伝えられない時もある。</span>
          <br />
          そんなもどかしい気持ちも、
          <span className={styles.nowrap}>音楽と共にシェアすることで</span>
          <br />
          少し色付いて見えたり、
          <span className={styles.nowrap}>
            より楽しめたりするんじゃないだろうか。
          </span>
          <br />
          そういった考えで
          <span className={styles.nowrap}>この企画はスタートしました。</span>
        </p>
        <p className={styles.text}>
          ぜひ皆さん自身の物語で、
          <br />
          『ロールシャッハシンドローム』を
          <br />
          鮮やかに縁取ってください。
        </p>
        <p className={styles.text}>
          そして『ロールシャッハシンドローム』が
          <br />
          これからも沢山の人に
          <span className={styles.nowrap}>愛され続けますように。</span>
        </p>
      </section>
      <section className={styles.section}>
        <h2 className={styles.title}>Eight arrangements and eight stories.</h2>
        <div className={styles.titleJa}>8つの編曲と8つの物語</div>
        <p className={styles.text}>
          『ロールシャッハシンドローム』を
          <span className={styles.nowrap}>イメージして制作された楽曲</span>
          <br />
          <span className={styles.nowrap}>『Outline feat.初音ミク』を元に</span>
          <br />
          <span className={styles.nowrap}>
            8つの編曲と8つの物語をご用意しました。
          </span>
        </p>
        <p className={styles.text}>
          たったの8つにはなりますが
          <br />
          丹精込めて作り上げたものです。
        </p>
        <p className={styles.text}>
          どうか、あなたの物語に似合うものが
          <br />
          見つかることを願っています。
        </p>
      </section>
      <section className={styles.section}>
        <h2 className={styles.title}>What is “Rorschach Syndrome"?</h2>
        <div className={styles.titleJa}>ロールシャッハシンドロームとは</div>
        <img
          className={styles.imageSenario}
          src={ImgSenario}
          alt="ロールシャッハシンドローム"
        />
        <p className={styles.textGray}>
          これから観測される結末は、
          <br />
          確定していないが
          <span className={styles.nowrap}>避けようのない厄難だ。</span>
          <br />
          願わくは、
          <span className={styles.nowrap}>閉じて開いた対称の輪郭が</span>
          <br />
          鮮やかであらんことを。
        </p>
        <p className={styles.text}>
          PLとDLが一対一で会話する、 <br />
          継続共鳴者向け短時間シナリオ。
        </p>
        <a
          className={styles.linkButton}
          href="https://talto.cc/projects/QtlDY2B38pUcE9sJOdfM4"
          target="_blank"
        >
          TALTO
        </a>
        <a
          className={styles.linkButton}
          href="https://booth.pm/ja/items/3846658"
          target="_blank"
        >
          BOOTH
        </a>
      </section>
      <section className={styles.sectionCredit}>
        <div className={styles.original}>
          原作　エモクロアTRPG
          <span className={styles.nowrap}>『ロールシャッハシンドローム』</span>
        </div>
        <div className={styles.original}>
          作者　<span className={styles.nowrap}>ディズム</span>　
          <a
            href="https://twitter.com/DizmKDC"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            https://twitter.com/DizmKDC
          </a>
          　
        </div>
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div className={styles.tableTitle}>
              Music「Outline feat.初音ミク」
            </div>
            <a
              href="https://youtu.be/K3bBNuMK7TM"
              className={styles.tableHeaderLink}
            >
              <div>
                MV <span className={styles.small}>(YouTube)</span>
              </div>
              <IconOpenInNew />
            </a>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>Vocal</div>
            <div className={styles.tableCol}>初音ミク</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>Music &amp; Arrange</div>
            <div className={styles.tableCol}>
              Twinfield&nbsp;&nbsp;
              <a
                href="https://twitter.com/Twinfieldnex"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                https://twitter.com/Twinfieldnex
              </a>
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>MIX &amp; Mastering</div>
            <div className={styles.tableCol}>Twinfield</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>Vocals mix</div>
            <div className={styles.tableCol}>Twinfield</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>Lyric</div>
            <div className={styles.tableCol}>
              da-ya &amp; ぜんざ&nbsp;&nbsp;
              <a
                href="https://twitter.com/zenzanze"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                https://twitter.com/zenzanze
              </a>
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div className={styles.tableTitle}>STAFF</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>BGM Arrange</div>
            <div className={styles.tableCol}>
              nob&nbsp;&nbsp;
              <a
                href="https://twitter.com/playthetrpg"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                https://twitter.com/playthetrpg
              </a>
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>Illustration</div>
            <div className={styles.tableCol}>
              浅田大根&nbsp;&nbsp;
              <a
                href="https://twitter.com/asadaikon"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                https://twitter.com/asadaikon
              </a>
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>Music Video</div>
            <div className={styles.tableCol}>
              わかる&nbsp;&nbsp;
              <a
                href="https://twitter.com/2wakaru5"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                https://twitter.com/2wakaru5
              </a>
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>Copywriting</div>
            <div className={styles.tableCol}>
              フィン&nbsp;&nbsp;
              <a
                href="https://twitter.com/sakanano_fin"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                https://twitter.com/sakanano_fin
              </a>
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>WEB Development</div>
            <div className={styles.tableCol}>
              酒田　シンジ&nbsp;&nbsp;
              <a
                href="https://twitter.com/ysakasin"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                https://twitter.com/ysakasin
              </a>
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.tableCol}>
              Story &amp; Direction &amp; WEB Design
            </div>
            <div className={styles.tableCol}>da-ya</div>
          </div>
        </div>
      </section>
      <div className={styles.copy}>&copy; 2022 Outline Project</div>
    </div>
  );
};

export default About;
