import React from "react";
import styles from "./Track.module.css";
import { useInView } from "react-intersection-observer";
import TRACKS from "../../tracks";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { ReactComponent as IconClose } from "./IconClose.svg";
import { ReactComponent as IconTwitter } from "./IconTwitter.svg";
import { useSetRecoilState } from "recoil";
import { currentTrackIndexState } from "../../recoil/atoms";

type TrackProps = {
  img: string;
  value: number;
  id: string;
  title: string;
  caption: string;
  ink: string;
};

const Track = ({ img, id, title, caption, value }: TrackProps) => {
  const { ref, inView } = useInView({ threshold: 0.2 });
  const [open, setOpen] = React.useState(false);
  const setCurrentTrackIndex = useSetRecoilState(currentTrackIndexState);

  React.useEffect(() => {
    if (inView) {
      setCurrentTrackIndex(value);
    }
  }, [inView, setCurrentTrackIndex, value]);

  return (
    <div id={id} className={styles.track}>
      <div className={styles.jacketWrap}>
        <img className={styles.jacket} src={img} ref={ref} />
        <section className={styles.musicShape}>
          <h2>{title}</h2>
          <div>{caption}</div>
          <button className={styles.readMore} onClick={() => setOpen(true)}>
            続きを読む
          </button>
        </section>
      </div>
      <button className={styles.readMoreSP} onClick={() => setOpen(true)}>
        続きを読む
      </button>
      <Modal open={open} trackId={id} onClose={() => setOpen(false)} />
    </div>
  );
};

const makeTwitterShareURL = (trackId: string, trackName: string): string => {
  const getParams = new URLSearchParams();
  getParams.append(
    "text",
    `——これがぼくらの物語。\n\n『${trackName}』\nhttps://outline-rs.com/?track=${trackId}\n\n#Outline #ぼくらのロールシャッハシンドロームは${trackName}`
  );

  return `https://twitter.com/intent/tweet?${getParams.toString()}`;
};

type ModalProps = {
  trackId: string;
  open: boolean;
  onClose: () => void;
};

const Modal = ({ trackId, open, onClose }: ModalProps) => {
  const [opening, setOpening] = React.useState(false);
  const [closing, setClosing] = React.useState(false);

  const track = React.useMemo(() => {
    return TRACKS.find((track) => track.id === trackId);
  }, [trackId]);

  React.useEffect(() => {
    if (open) {
      setOpening(true);
      setClosing(false);
    }
  }, [open, setOpening]);

  const handleClose = () => {
    setOpening(false);
    setClosing(true);
    window.setTimeout(() => {
      onClose();
    }, 500);
  };

  const portal = document.getElementById("modal-root");

  if (!open || track == null || portal == null) {
    return null;
  }

  const shareUrl = makeTwitterShareURL(track.id, track.name);

  const onClickShare = () => {
    ReactGA.event("share", {
      method: "Twitter",
      content_type: "music",
      item_id: trackId,
    });
  };

  return ReactDOM.createPortal(
    <>
      <div
        className={styles.modal}
        data-opening={opening}
        data-closing={closing}
      >
        <button
          className={styles.buttonClose}
          onClick={handleClose}
          data-color={track.flavorTextColor}
        >
          <IconClose />
          close
        </button>
        <div className={styles.modalContent} data-color={track.flavorTextColor}>
          <div className={styles.trackBody}>
            <h1 className={styles.trackName}>{track.name}</h1>
            <div className={styles.trackLead}>{track.lead}</div>
            <p className={styles.trackText}>{track.text}</p>
            <a
              className={styles.voteButton}
              onClick={onClickShare}
              href={shareUrl}
              role="button"
              data-color={track.flavorTextColor}
              target="_blank"
            >
              <IconTwitter />
              これがぼくらの物語
            </a>
          </div>
        </div>
      </div>
      <div
        className={styles.cdTransitionLayer}
        data-visible={open}
        data-close={closing}
        data-open={opening}
      >
        <BackgroundLayer
          backgroundImage={track.ink}
          lastFrame={track.inkLastFrame}
        />
      </div>
    </>,
    portal
  );
};

const aspect = 1.78;
const frames = 25;

const calcLayerSize = (): { width: number; height: number } => {
  let windowHeight = window.innerHeight;
  let windowWidth = window.innerWidth;

  if (windowWidth / windowHeight > aspect) {
    return { width: windowWidth, height: windowWidth / aspect };
  } else {
    return { width: windowHeight * aspect, height: windowHeight };
  }
};

type BackgroundLayerProps = {
  backgroundImage: string;
  lastFrame: string;
};

const BackgroundLayer = ({
  backgroundImage,
  lastFrame,
}: BackgroundLayerProps) => {
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    const { width, height } = calcLayerSize();
    setWidth(width);
    setHeight(height);

    window.onresize = () => {
      const { width, height } = calcLayerSize();
      setWidth(width);
      setHeight(height);
    };

    return () => {
      window.onresize = null;
    };
  }, []);

  return (
    <>
      <div
        className={styles.bgLayer}
        data-ink="piano"
        style={{
          width: `${width * frames}px`,
          height: `${height}px`,
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>
      <div
        className={styles.bgLayerLast}
        data-ink="piano"
        style={{
          width: `${width}px`,
          height: `${height}px`,
          backgroundImage: `url(${lastFrame})`,
        }}
      ></div>
    </>
  );
};

export default Track;
