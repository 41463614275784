import styles from "./MusicVideo.module.css";

type MusicVideoProps = {
  open: boolean;
  onClose: () => void;
};

const MusicVideo = ({ open, onClose }: MusicVideoProps) => {
  if (!open) {
    return null;
  }

  return (
    <>
      <div className={styles.overlay} onClick={onClose} />
      <iframe
        className={styles.player}
        width="1120"
        height="630"
        src="https://www.youtube.com/embed/K3bBNuMK7TM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </>
  );
};

export default MusicVideo;
