import React from "react";
import styles from "./Confirm.module.css";
import Lamp from "./images/lamp.gif";

type ConfirmProps = {
  onNext: () => void;
};

const Confirm = ({ onNext }: ConfirmProps) => {
  return (
    <div className={styles.confirm}>
      <img className={styles.lamp} src={Lamp} alt="ランプ" />
      <p className={styles.note}>
        <span>このサイトには</span>
        <span>エモクロアTRPGシナリオ</span>
        <span>『ロールシャッハシンドローム』</span>
        <span>に関する</span>
        <span>重大なネタバレや</span>
        <span>独自の解釈と表現が含まれています。</span>
      </p>
      <div className={styles.box}>
        <a className={styles.link} href="https://booth.pm/ja/items/3846658">
          NO
        </a>
        <div className={styles.ask}>よろしいですか?</div>
        <button className={styles.ok} onClick={onNext}>
          YES
        </button>
      </div>
    </div>
  );
};

export default Confirm;
