import React from "react";
import Confirm from "./Confirm";
import Main from "./pages/Main";
import "normalize.css";

type PageKind = "confirm" | "sound" | "main";

function App() {
  const [page, setPage] = React.useState<PageKind>("confirm");

  if (page === "confirm") {
    return <Confirm onNext={() => setPage("sound")} />;
  }

  return <Main />;
}

export default App;
