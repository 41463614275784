import { useEffect } from "react";
import { useRecoilCallback } from "recoil";
import { currentTrackIndexState } from "../../recoil/atoms";
import TRACKS from "../../tracks";

const PageVisibilityListener = () => {
  const scrollToCurrentTrack = useRecoilCallback(({ snapshot }) => async () => {
    const currentTrackIndex = await snapshot.getPromise(currentTrackIndexState);
    const track = TRACKS[currentTrackIndex];
    if (track == null) {
      return;
    }

    const elem = document.getElementById(track.id);
    elem?.scrollIntoView();
  });

  useEffect(() => {
    const listener = () => {
      if (document.visibilityState === "visible") {
        scrollToCurrentTrack();
      }
    };
    document.addEventListener("visibilitychange", listener);

    return () => {
      document.removeEventListener("visibilitychange", listener);
    };
  }, [scrollToCurrentTrack]);
  return null;
};

export default PageVisibilityListener;
