import React, { useEffect } from "react";
import styles from "./Main.module.css";
import { ReactComponent as IconMenuButton } from "./IconMenuButton.svg";
import { ReactComponent as IconOpenInNew } from "./IconOpenInNew.svg";
import Track from "./Track";
import Sidemenu from "../components/Sidemenu";
import TRACKS from "../tracks";
import AudioPlayer from "../components/AudioPlayer";
import About from "../components/About";
import BackgroundVideo from "../components/BackgroundVideo";
import SoundConfirm from "../SoundConfirm";
import MusicVideo from "../components/MusicVideo";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  pauseBackgroundState,
  forcePauseState,
  openSidemenuState,
} from "../recoil/atoms";
import PageVisibilityListener from "../components/PageVisibilityListener/PageVisibilityListener";

const Main = () => {
  const [confirmed, setConfirm] = React.useState(false);
  const [fadeout, setFadeout] = React.useState(false);
  const [open, setOpen] = useRecoilState(openSidemenuState);
  const [openMV, setOpenMV] = React.useState(false);

  const [openAbout, setOpenAbout] = React.useState(false);
  const onFadeout = () => {
    setFadeout(true);
    window.setTimeout(() => {
      setConfirm(true);
    }, 1000);
  };

  const setPauseBackground = useSetRecoilState(pauseBackgroundState);
  const setForcePause = useSetRecoilState(forcePauseState);
  const handleOpenMV = () => {
    setForcePause(true);
    setPauseBackground(true);
    setOpenMV(true);
  };

  const handleCloseMV = () => {
    setForcePause(false);
    setPauseBackground(false);
    setOpenMV(false);
  };

  useEffect(() => {
    if (confirmed && window.location.search.length > 0) {
      const params = new URLSearchParams(window.location.search);
      const id = params.get("track");
      if (id == null) {
        return;
      }

      window.setTimeout(() => {
        const elem = document.getElementById(id);
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth" });
        }
      }, 1100);
    }
  }, [confirmed]);

  return (
    <div className={styles.wrap}>
      <div className={styles.mainarea}>
        <div className={styles.header}>
          <div className={styles.headerMain}>
            <div className={styles.headerTitleArea}>
              <h1 className={styles.headerTitle}>
                <a className={styles.headerTitleLink} href="/">
                  Outline
                </a>
              </h1>
              <div className={styles.headerSubtitle}>
                ロールシャッハシンドローム 二次創作ファンサイト
              </div>
            </div>
            {confirmed && (
              <div className={styles.headerMenu}>
                <div
                  className={styles.headerMenuItem}
                  onClick={() => setOpenAbout(true)}
                  role="button"
                >
                  What's this site?
                </div>
                <div className={styles.headerMenuItem} onClick={handleOpenMV}>
                  Music Video
                </div>
                <a
                  className={styles.headerMenuItem}
                  href="https://da-ya.booth.pm/items/4072549"
                  target="_blank"
                >
                  Booth
                  <IconOpenInNew />
                </a>
              </div>
            )}
          </div>
          {confirmed && (
            <IconMenuButton
              role="button"
              className={styles.headerMenuButton}
              onClick={() => setOpen(!open)}
              data-hidden={open}
            />
          )}
        </div>
        {confirmed ? (
          <>
            <div className={styles.body} id="scrollBody">
              <div className={styles.main}>
                {TRACKS.map((track, index) => {
                  return (
                    <Track
                      key={track.id}
                      id={track.id}
                      title={track.name}
                      caption={track.lead}
                      img={track.jacket}
                      ink={track.ink}
                      value={index}
                    />
                  );
                })}
              </div>
            </div>
            <PageVisibilityListener />
          </>
        ) : (
          <SoundConfirm fadeout={fadeout} onNext={onFadeout} />
        )}
        <AudioPlayer hidden={!confirmed} />
        <BackgroundVideo blur={fadeout} />
      </div>
      <Sidemenu open={open} onClose={() => setOpen(false)} />
      <About open={openAbout} onClose={() => setOpenAbout(false)} />
      <MusicVideo open={openMV} onClose={handleCloseMV} />
    </div>
  );
};

export default Main;
